<template>
  <auth-layout :img-url="imgUrl">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
      <span v-if="user">Sayın {{ user.fullname }},</span> <br />
      <br />
      Riskomer'e Hoşgeldiniz! 👋
    </b-card-title>
    <b-card-text class="mb-2"> Sisteme ilk defa giriş yaptığınız için lütfen şifrenizi değiştiriniz. </b-card-text>
    <validation-observer ref="passwordchange">
      <app-input
        id="password"
        v-model="password"
        type="password"
        name="Şifre"
        label="Şifre:"
        placeholder="············"
        rules="required"
        @keyup.enter.native="changePasswordFirstLogin"
      />
      <app-input
        v-model="passwordCheck"
        type="passwordCheck"
        name="Şifre Doğrulama"
        label="Şifre Doğrulama:"
        placeholder="············"
        rules="required|confirmed:password"
        @keyup.enter.native="changePasswordFirstLogin"
      />
      <b-button variant="primary" type="submit" block @click="changePasswordFirstLogin"> Oluştur </b-button>
      <b-card-text class="text-center mt-2">
        <b-link @click="redirectLogin">
          <span>Çıkış Yap</span>
        </b-link>
      </b-card-text>
    </validation-observer>
  </auth-layout>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import AuthLayout from "../AuthLayout.vue";

export default {
  components: {
    ValidationObserver,
    AuthLayout,
  },
  data() {
    return {
      password: null,
      passwordCheck: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    imgUrl() {
      return this.$store.state.appConfig.layout.skin === "dark"
        ? require("@/assets/images/pages/forgot-password-v2-dark.svg")
        : require("@/assets/images/pages/forgot-password-v2.svg");
    },
  },
  methods: {
    changePasswordFirstLogin() {
      this.$validate(this.$refs.passwordchange, () => {
        this.$store.dispatch("changeTempPassword", { password: this.password });
      });
    },
    redirectLogin() {
      this.$store.dispatch("logout", { sendLogoutReq: true });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
